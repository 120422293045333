import React, { useEffect } from "react";
import {
  Col,
  Container,
  Image,
  Row,
  Button,
  Badge,
  Table,
} from "react-bootstrap";
import "../styles/weather-data.scss";
import { Link } from "gatsby";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import HeaderExpanded from "../components/HeaderExpanded/HeaderExpanded";
import { Helmet } from "react-helmet";

function WeatherData(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const style = {
    position: "relative",
    width: "100%",
    height: "600px",
  };

  const title = <div>Weather Data &amp; Weather API</div>;
  const image = process.env.GATSBY_S3_BUCKET_ASSETS + "images/nz_hills.jpg";

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | NZ Weather Data &amp; Weather API</title>
        <meta
          name="description"
          content="HortPlus is a New Zealand-based agricultural technology firm. We
              have been delivering digital solutions to the primary sector for
              over fifteen years"
        />
      </Helmet>
      <HeaderExpanded title={title} image={image} />
      <Container className="my-5">
        <Row className="d-flex justify-content-start pb-2">
          <Col>
            <h6 className="hp-font-28 pb-2">
              NZ’s trusted source of agricultural weather data for business and
              researchers.
            </h6>
            <p>
              HortPlus provides Managed Weather Data Services and Weather Data
              API access for commercial and research users who are after
              high-quality and reliable data.
            </p>
            <p>
              Our weather data is sourced from the HortPlus Weather network, a
              network of scientific-grade weather stations covering all the
              major agricultural growing areas in New Zealand.
            </p>
          </Col>
        </Row>
        <hr></hr>
        <Row className="mt-5">
          <Col>
            <p className="hp-font-22">
              <b>The HortPlus Weather Station Network</b>
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-top pt-4">
          <Col md={6} className="mb-5">
            <div className="pb-3">
              <p>
                HortPlus manages a network of research-grade weather stations
                covering all the major growing areas of New Zealand. All of
                these weather stations provide hourly data that is loaded into
                our weather database.
              </p>
              <p>
                All of our stations are professionally installed, or have been
                installed by one of our partners to scientific or WMO (World
                Meteorological Organisation) standards. We maintain historical
                data sets for all stations with periods of up to 20 years.
              </p>
              <p>
                You can make use of our weather data in GIS and research
                applications, overlaying data and running data analytics to
                develop new tools and models.
              </p>
              <p>
                If you have access to existing weather stations &amp; hardware
                these can be added to our network to build a tailored private
                network to meet your own needs. Uses for this might include
                research trials or specific projects where a single source of
                managed data is important.
              </p>
              <p>
                We provide programmatic API access and easy web-based platform
                access to our weather station network.
              </p>
              <div className="bg-light p-4 text-center">
                <strong>Keywords</strong> <br />
                <div>
                  <Badge pill bg="primary-outline">
                    Agricultural Weather Data
                  </Badge>
                  {" / "}{" "}
                  <Badge pill bg="primary">
                    Rural Weather Data
                  </Badge>
                  {" / "}
                  <Badge pill bg="primary-outline">
                    Private Weather Network
                  </Badge>
                  {" / "}
                  <Badge pill bg="primary-outline">
                    Research-grade Weather Station
                  </Badge>
                  {" / "}{" "}
                  <Badge pill bg="primary">
                    API
                  </Badge>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="mb-5">
            <Map
              google={props.google}
              initialCenter={{
                lat: -41.282896301887334,
                lng: 174.777598255194,
              }}
              zoom={5}
              containerStyle={style}
              className="hp-map-card"
            >
              {/* HortPlus */}
              <Marker
                name={"Fisher Road"}
                position={{ lat: -37.40702, lng: 175.86993 }}
              />
              <Marker
                name={"Puketapu"}
                position={{ lat: -39.52152, lng: 176.7766 }}
              />
              <Marker
                name={"Te Aute"}
                position={{ lat: -39.68113, lng: 176.83607 }}
              />
              <Marker
                name={"Trinity Hill"}
                position={{ lat: -39.61662, lng: 176.72145 }}
              />
              <Marker
                name={"Martinborough"}
                position={{ lat: -39.61662, lng: 175.47348 }}
              />
              <Marker
                name={"Cental Moutere"}
                position={{ lat: -41.17672, lng: 172.97353 }}
              />
              <Marker
                name={"Landsdowne Road"}
                position={{ lat: -41.31315, lng: 173.13417 }}
              />
              <Marker
                name={"Tasman"}
                position={{ lat: -41.20515, lng: 173.06252 }}
              />
              <Marker
                name={"Richmond"}
                position={{ lat: -41.34189, lng: 173.16263 }}
              />
              {/* PFR */}
              <Marker
                name={"Kerikeri"}
                position={{ lat: -35.17862, lng: 173.93021 }}
              />
              <Marker
                name={"Owairaka"}
                position={{ lat: -36.89035, lng: 174.72752 }}
              />
              <Marker
                name={"Pukekohe"}
                position={{ lat: -37.20389, lng: 174.86335 }}
              />
              <Marker
                name={"Bombay"}
                position={{ lat: -37.18034, lng: 175.00481 }}
              />
              <Marker
                name={"Bombay"}
                position={{ lat: -37.18034, lng: 175.00481 }}
              />
              <Marker
                name={"Pukekawa"}
                position={{ lat: -37.3011, lng: 174.9765 }}
              />
              <Marker
                name={"Onewhero"}
                position={{ lat: -37.34755, lng: 174.9001 }}
              />

              <Marker
                name={"Rukuhia"}
                position={{ lat: -37.8591, lng: 175.32269 }}
              />
              <Marker
                name={"Te Puke"}
                position={{ lat: -37.82284, lng: 176.32065 }}
              />
              <Marker
                name={"Bay View"}
                position={{ lat: -39.40311, lng: 176.8773 }}
              />
              <Marker
                name={"Twyford"}
                position={{ lat: -39.60045, lng: 176.81376 }}
              />
              <Marker
                name={"Pakowhai"}
                position={{ lat: -39.56174, lng: 176.85915 }}
              />
              <Marker
                name={"Lawn Road"}
                position={{ lat: -39.60821, lng: 176.91385 }}
              />
              <Marker
                name={"Ruahapia"}
                position={{ lat: -39.62509, lng: 176.87662 }}
              />
              <Marker
                name={"Havelock North"}
                position={{ lat: -39.65485, lng: 176.87624 }}
              />
              <Marker
                name={"Longlands"}
                position={{ lat: -39.6612, lng: 176.8042 }}
              />
              <Marker
                name={"Onga Onga"}
                position={{ lat: -39.87859, lng: 176.4322 }}
              />
              <Marker
                name={"Rarangi"}
                position={{ lat: -41.42799, lng: 174.008 }}
              />
              <Marker
                name={"Rapaura Upper"}
                position={{ lat: -41.47838, lng: 173.83389 }}
              />
              <Marker
                name={"Rapaura"}
                position={{ lat: -41.4865, lng: 173.85925 }}
              />
              <Marker
                name={"Rapaura Central"}
                position={{ lat: -41.49137, lng: 173.8889 }}
              />
              <Marker
                name={"Renwick"}
                position={{ lat: -41.5052, lng: 173.85181 }}
              />
              <Marker
                name={"Marlborough"}
                position={{ lat: -41.49724, lng: 173.96286 }}
              />
              <Marker
                name={"Brancott"}
                position={{ lat: -41.54247, lng: 173.8476 }}
              />
              <Marker
                name={"Waihopai"}
                position={{ lat: -41.5563, lng: 173.77795 }}
              />
              <Marker
                name={"Wairau Valley"}
                position={{ lat: -41.54999, lng: 173.57001 }}
              />
              <Marker
                name={"Seaview-Awatere"}
                position={{ lat: -41.62917, lng: 174.12981 }}
              />
              <Marker
                name={"Tohu"}
                position={{ lat: -41.73787, lng: 173.87415 }}
              />
              <Marker
                name={"Riwaka"}
                position={{ lat: -41.09638, lng: 172.97154 }}
              />
              <Marker
                name={"Lincoln"}
                position={{ lat: -43.64613, lng: 172.45668 }}
              />
              <Marker
                name={"Cromwell"}
                position={{ lat: -45.29986, lng: 169.44553 }}
              />
              <Marker
                name={"Clyde"}
                position={{ lat: -45.20331, lng: 169.31825 }}
              />
              <Marker
                name={"Rockview"}
                position={{ lat: -45.22106, lng: 169.41664 }}
              />
              <Marker
                name={"Roxburgh East"}
                position={{ lat: -45.511, lng: 169.32401 }}
              />
              <Marker
                name={"Ettrick"}
                position={{ lat: -45.63986, lng: 169.55553 }}
              />
              {/* { MetService } */}
              <Marker
                name={"Puerua"}
                position={{ lat: -35.12718, lng: 174.01518 }}
              />
              <Marker
                name={"Kerikeri"}
                position={{ lat: -35.263, lng: 173.911 }}
              />
              <Marker
                name={"Kaikohe"}
                position={{ lat: -35.42217, lng: 173.82217 }}
              />
              <Marker
                name={"Whangarei"}
                position={{ lat: -35.76718, lng: 174.3642 }}
              />
              <Marker
                name={"Whangaparaoa"}
                position={{ lat: -36.60313, lng: 174.835 }}
              />
              <Marker
                name={"Auckland"}
                position={{ lat: -37.009, lng: 174.80701 }}
              />
              <Marker
                name={"Tauranga"}
                position={{ lat: -37.67112, lng: 176.1962 }}
              />
              <Marker
                name={"Rotorua"}
                position={{ lat: -38.107, lng: 176.31599 }}
              />
              <Marker
                name={"Whakatane"}
                position={{ lat: -37.92419, lng: 176.91919 }}
              />
              <Marker
                name={"Gisborne"}
                position={{ lat: -37.92419, lng: 176.91919 }}
              />
              <Marker
                name={"New Plymouth"}
                position={{ lat: -39.00766, lng: 174.17781 }}
              />
              <Marker
                name={"Palmerston North"}
                position={{ lat: -40.319, lng: 175.61501 }}
              />
              <Marker
                name={"Levin"}
                position={{ lat: -40.622, lng: 175.35699 }}
              />
              <Marker
                name={"Masterton"}
                position={{ lat: -40.97635, lng: 175.63907 }}
              />
              <Marker
                name={"Timaru"}
                position={{ lat: -44.305, lng: 171.22501 }}
              />
              <Marker
                name={"Hurunui"}
                position={{ lat: -42.897, lng: 172.72 }}
              />
              <Marker
                name={"Gore"}
                position={{ lat: -46.115, lng: 168.88699 }}
              />
            </Map>

            <small className="mt-2 d-block">
              Map of the core HortPlus Weather Station Network (HPWN). <br />
              Please <Link to={"/contact"}>contact us</Link> for detailed
              station information and locations.
            </small>
          </Col>
        </Row>
        <hr className="mb-3" />
        <Row>
          <Col className="p-3 p-md-5" xs={12} md={6}>
            <p className="hp-font-22">
              <b>Weather Data API Access</b>
            </p>
            <p>
              We provide a secure Weather Data API for programmatic access that
              can handle a high throughput and can be directly integrated with
              PowerBI Dashboards, existing apps and web services, and used to
              pull data in for commercial &amp; research tools.
            </p>
            <p>
              We provide comprehensive API documentation and support for getting
              up and running with our Weather Data API and integrating it with
              your systems and pipeline. This includes a standard python and PHP
              library for bootstrapping projects, and we are also able to offer
              consulting services to help develop your integration.
            </p>
          </Col>
          <Col className="p-3 p-md-5" xs={12} md={6}>
            <p className="hp-font-22">
              <b>Web Weather Data File Access</b>
            </p>
            <p>
              You can access the MetWatch Platform directly to view and export
              weather data yourself. You can create individual exports of data
              for specific variables and periods in a standard CSV format.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="p-3 p-md-5" xs={12} md={6}>
            <p className="hp-font-22">
              <b>Managed &amp; Curated Weather Data</b>
            </p>
            <div className="pb-3">
              <p>
                Our weather data is curated and monitored to provide a
                consistent and truthful data set. We perform bi-weekly data
                checking, cross-referencing and validation to ensure that the
                data is consistent.
              </p>

              <p>
                Our weather data curation and management is trusted by
                organisations such as Plant &amp; Food Research, New Zealand
                Apples and Pears, and Onions New Zealand.
              </p>

              <p>
                Our stations are regularly calibrated and maintained, with
                standardised installation and siting of all locations. All of
                our stations are GPS mapped with installation photos and
                servicing records held on file.
              </p>

              <p>
                We keep a full record of raw, and patched weather data as well
                as raw data inputs.
              </p>
            </div>
          </Col>
          <Col className="p-3 p-md-5" xs={12} md={6}>
            <p className="hp-font-22">
              <b>Weather Station vendors</b>
            </p>
            <p>
              We can connect and integrate your existing weather station
              hardware to create a Private Weather Station Networks.
            </p>
            <strong>Harvest Network</strong>
            <p>
              HortPlus is able to{" "}
              <Link to={"/connect-harvest-weather-station-to-hortplus-network"}>
                connect Harvest Weather Stations
              </Link>{" "}
              to our network. All we require is the weather station owner’s
              permission to connect the stations, adding them to a Private
              Weather Station Network that only you have access to. This
              provides a single secure point of access for all your Harvest
              weather stations in addition to the main HortPlus Network.
            </p>
            <strong>Scott Technical Instruments</strong>
            <p>
              We can also connect with Campbell Scientific and Davis weather
              stations provided by Scott Technical Instruments in New Zealand.
            </p>
            <strong>Other Providers</strong>
            <p>
              <Link to={"/contact"}>Please get in touch.</Link>
            </p>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <p className="hp-font-28">
              <b>Weather Data Variables &amp; Station Information</b>
            </p>
            <strong className="d-block pb-2">Weather Data Variables</strong>
            <Table bordered>
              <thead>
                <tr>
                  <th>Weather Variable</th>
                  <th>Data Granularity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Air Temperature</td>
                  <td>Hourly / Daily</td>
                </tr>
                <tr>
                  <td>Rainfall</td>
                  <td>Hourly / Daily</td>
                </tr>

                <tr>
                  <td>Leaf Wetness</td>
                  <td>Hourly / Daily</td>
                </tr>

                <tr>
                  <td>Relative Humidity</td>
                  <td>Hourly / Daily</td>
                </tr>

                <tr>
                  <td>Soil Temperature</td>
                  <td>Hourly / Daily</td>
                </tr>

                <tr>
                  <td>Solar Radiation</td>
                  <td>Hourly / Daily</td>
                </tr>

                <tr>
                  <td>Evapotranspiration</td>
                  <td>Hourly / Daily</td>
                </tr>
                <tr>
                  <td>Wind Speed</td>
                  <td>Hourly / Daily</td>
                </tr>
                <tr>
                  <td>Wind Direction</td>
                  <td>Hourly / Daily</td>
                </tr>
                <tr>
                  <td>Wind Run</td>
                  <td>Hourly / Daily</td>
                </tr>
              </tbody>
            </Table>
            <strong className="d-block pb-2 pt-4">
              Weather Station Information
            </strong>
            <Table bordered>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Station Name</td>
                  <td>E.g. Lincoln University</td>
                </tr>
                <tr>
                  <td>Station ID</td>
                  <td>
                    Short ID for referencing in tools, e.g. Lincoln University =
                    LUN
                  </td>
                </tr>

                <tr>
                  <td>Region</td>
                  <td>Region within New Zealand, e.g. Canterbury</td>
                </tr>

                <tr>
                  <td>Backup Station</td>
                  <td>
                    Backup station data to use if primary station data feed
                    becomes unavailable
                  </td>
                </tr>

                <tr>
                  <td>Co-ordinates</td>
                  <td>Latitude, Longitude</td>
                </tr>

                <tr>
                  <td>Altitude</td>
                  <td>Elevation in metres above sea level</td>
                </tr>

                <tr>
                  <td>Logger / Sensor model</td>
                  <td>e.g. CR200X</td>
                </tr>

                <tr>
                  <td>Establishment Date</td>
                  <td>E.g. 2010-4-18</td>
                </tr>

                <tr>
                  <td>Calibration Date</td>
                  <td>E.g. 2005-4-18</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="hp-page hp-page--blue-grey d-flex flex-column align-items-center"
      >
        <Row noGutters>
          <Col>
            <Container as="h2" className="hp-title pb-3 pb-md-5 text-center">
              Weather Data Pricing
            </Container>
            <Container className="pb-md-5 text-left">
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <div className="pb-3">
                    <h5>Web-based Weather Data File Access</h5>
                    <p>
                      Manual weather data export access for single users is
                      available via a MetWatch subscription with Export
                      Functionality enabled (All New Zealand Stations).
                    </p>
                    <p>
                      <strong>$1,586.25 NZD per year + GST</strong>
                    </p>
                  </div>
                  <div className="pb-3">
                    <h5>Weather Data API Access</h5>
                    <ul>
                      <li>Upto 60 requests per minute</li>
                      <li>Access to API and integration documentation</li>
                    </ul>
                    <p>
                      <strong>$570.00 NZD per year per station + GST</strong>
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <p>
                    If there is specific data that you are looking for, then
                    contact us and we can discuss further what you require and
                    what we can supply.
                  </p>

                  <Button as={Link} to={"/contact"}>
                    Contact Us
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// This prevents the default text 'Loading...' from showing
const LoadingContainer = () => <></>;

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_API_KEY,
  LoadingContainer: LoadingContainer,
})(WeatherData);
